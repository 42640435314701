import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import projectImg from "../assets/img/project-img.webp";
import gifImg from "../assets/img/gif-img.webm";
import Fade from "./Fade";

const ProjectSection = () => {
  return (
    <section id="introduction" className="ProjectSection">
      <Container>
        <Row>
          <Col lg={6}>
            <Fade>
              <h1 className="mainHeading">PROJECT EXPLANATION</h1>
            </Fade>
            <Fade>
              <p className="mainText">
                There are 2 stages of NoMaiMai holders - Sana and Kenji
              </p>
              <p className="mainText odd">
                Sana <br /> Every holder of NoMaiMai is considered to be Sana. Sana gets to enjoy all the basic Utilities and Perks included in the project. Sana is the first stage of the NoMaiMai evolution. Sana evolves into Kenji after it’s in possession of either 5+ common NFTs or 1 Super Animated NFT! 

              </p>
              <p className="mainText odd">
                Kenji <br /> is the evolved state of Sana. Kenji not only gets to enjoy all the basic Perks and Utilities but also gets access to exclusive perks in real life. You evolve into Kenji when you are in possession of either 5+ common NFTs or 1 Super Animated NFT!
              </p>
            </Fade>
          </Col>
          <Col className="d-none d-md-block mt-5 mt-lg-0" lg={6}>
            <Fade>
              <video
                className="gifImg"
                loop="true"
                autoplay="autoplay"
                controls={false}
                muted
              >
                <source type="video/mp4" src={gifImg}></source>
              </video>
            </Fade>
          </Col>
          <Col className="d-md-none mt-5 mt-lg-0" lg={6}>
            <Fade>
              <img src={projectImg} alt="" />
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ProjectSection;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import userImg1 from "../assets/img/user-img-1.webp";
import userImg2 from "../assets/img/user-img-2.webp";
import Fade from "./Fade";

const PerksSection = () => {
  return (
    <section className="PerksSection">
      <Container>
        <Row>
          <Col lg={11}>
            <Fade>
              <h2 className="mainHeading">PERKS AND UTILITIES</h2>
            </Fade>
            <Fade>
              <p className="mainText odd">
                NoMaiMai as an NFT collection aims to create a community of
                next-gen investors by offering a platform to help you earn as
                well as enjoy some exclusive real-life perks. From profit
                sharing in various businesses, Stock Trading, Crypto Trading &
                Mentorship Program to Discounts, Goa Stay & Portfolio Management
                Service we have the most perk-loaded NFT in the country. As you
                read before there are 2 stages of NoMaiMai holders – Sana and
                Kenji, here is the list of perks for both of them.
              </p>
            </Fade>
          </Col>
        </Row>
        <Row className="mt-0 pt-0 pt-lg-5 mt-lg-5">
          <Col className="customCol" xs={8}>
            <Fade>
              <h2 className="mainHeading odd">PROFIT SHARING</h2>
            </Fade>
            <Fade>
              <div className="perksContainer">
                <div className="mainText">
                  Profit sharing from{" "}
                  <span className="styledText">NoMaiMai </span> Café and Club
                </div>
                <div className="mainText">
                  Profit sharing from{" "}
                  <span className="styledText">NoMaiMai </span> Merch Line
                </div>
                <div className="mainText">
                  Profit sharing from Ridiculusum Clothing Label
                </div>
                <div className="mainText">Profit sharing from NoMaiMai Mobile game</div>
                <div className="mainText">
                  Profit sharing from{" "}
                  <span className="styledText">NoMaiMai </span> Animated Series
                </div>
              </div>
            </Fade>
          </Col>
          <Col xs={2} className="mt-5 mt-lg-0">
            <Fade>
              <img className="userImg" src={userImg1} alt="" />
            </Fade>
            <Fade>
              <h2 className="mainHeading odd  ms-0 ms-lg-4">Sana</h2>
            </Fade>
            <Fade>
              <div className="perksContainer">
                <div className="mainText odd">7%</div>
                <div className="mainText odd">7%</div>
                <div className="mainText odd">7%</div>
                <div className="mainText odd">5%</div>
                <div className="mainText odd">5%</div>
              </div>
            </Fade>
          </Col>
          <Col xs={2} className="mt-5 mt-lg-0">
            <Fade>
              <img className="userImg" src={userImg2} alt="" />
            </Fade>
            <Fade>
              <h2 className="mainHeading odd ms-0 ms-lg-4">Kenji</h2>
            </Fade>
            <Fade>
              <div className="perksContainer">
                <div className="mainText odd">15%</div>
                <div className="mainText odd">15%</div>
                <div className="mainText odd">15%</div>
                <div className="mainText odd">10%</div>
                <div className="mainText odd">10%</div>
              </div>
            </Fade>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg={11}>
            <Fade>
              <p className="mainText">
                In addition to all of these profit-sharing perks, all{" "}
                <span className="styledText">NoMaiMai </span>
                Holders get Access to our Premium Crypto Trading Group, A
                Complete Stock Trading Course and 25% OFF on any order at Simple
                Burger and Mr. Kiyoshi.
              </p>
              <p className="mainText">
                Moreover, being a KENJI has its own benefits, adding on to
                everything mentioned above, KENJI holders will get access to a
                Portfolio Management Service, a Mentorship Program (career and
                business associated) and FREE Stay in Goa for 1-2 Nights on
                Double Occupancy.
              </p>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PerksSection;

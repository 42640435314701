import "./App.css";
import HeroSection from "./components/HeroSection";
import PageHeader from "./components/PageHeader";
import ProjectSection from "./components/ProjectSection";
// import WalletSection from "./components/WalletSection";
import WalletSection2 from "./components/WalletSection2";
import CarouselSection from "./components/CarouselSection";
import PerksSection from "./components/PerksSection";
import FaqSection from "./components/FaqSection";
import TeamSection from "./components/TeamSection";
import Footer from "./components/Footer";
import { FaWhatsapp } from "react-icons/fa";

function App() {
  return (
    <div className="App">
      <PageHeader />
      <HeroSection />

      <WalletSection2 />
      <ProjectSection />
      <CarouselSection />
      <PerksSection />
      <TeamSection />
      <FaqSection />
      <Footer />
      <a className="contactBtn" href="https://wa.me/9315593703">
        <FaWhatsapp /> Contact Us
      </a>
    </div>
  );
}

export default App;

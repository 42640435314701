import React, { useEffect, useState, useRef } from "react";
import Web3 from "web3";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import defiwalletlogo from "./defi.jpeg";
import Fade from "./Fade";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

// Ethers
import { ethers, Signer } from "ethers";
import { DeFiWeb3Connector } from "deficonnect";

// Web3Modal
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

// Contract
import abi from "../ABI/contract.json";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

const WalletSection2 = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [chainId, setchainId] = useState(1);
  const [Connector, setConnector] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 4000,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 88987,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  const _chainId = 56;
  const _chainname = "Binance Network";

  const [totalSupply, setTotalSupply] = useState(0);
  const toEther = (wei) => ethers.utils.formatEther(wei).toString();
  const [provider, setProvider] = useState();
  const [Cost, setCost] = useState(0);
  const [MCost, setMCost] = useState();
  const [account, setaccount] = useState();
  const [fee, setfee] = useState();
  const handleConnection = async () => {
    const providerOptions = {
      injected: {
        display: {
          logo: "https://github.com/MetaMask/brand-resources/raw/master/SVG/metamask-fox.svg",
          name: "MetaMask",
          description: "Connect with MetaMask in your browser",
        },
        package: null,
      },
      "custom-walletconnect": {
        display: {
          logo: "https://docs.walletconnect.com/img/walletconnect-logo.svg",
          name: "WalletConnect",
          description: "Connect with any WalletConnect compatible wallet.",
        },
        options: {
          appName: "CRO CROW NFT",
          networkUrl: "https://polygon-rpc.com/",
          chainId: 137,
        },
        package: WalletConnectProvider,
        connector: async () => {
          const connector = new WalletConnectProvider({
            rpc: {
              56: "https://bsc-dataseed1.ninicoin.io/",
            },
            chainId: 56,
            clientMeta: {
              description: "CRO CROW NFT",
              icons: ["https://crocrow.com/logo.png"],
              name: "CRO CROW NFT",
              url: "https://crocrow.com/",
            },
          });
          await connector.enable();
          setConnector(connector);
          return connector;
        },
      },
      "custom-defiwallet": {
        display: {
          logo: defiwalletlogo,
          name: "Crypto.com DeFi Wallet",
          description: "Connect with DeFi Wallet browser extension or app",
        },
        options: {},
        package: WalletConnectProvider,
        connector: async () => {
          const connector = new DeFiWeb3Connector({
            supportedChainIds: [56],
            rpc: {
              56: "https://bsc-dataseed1.ninicoin.io/", // cronos mainet
            },
          });

          await connector.activate();
          setConnector(connector);
          let provider = await connector.getProvider();
          return provider;
        },
      },
    };

    const providerOptionss = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          chainId: 56,
          rpc: {
            56: "https://bsc-dataseed1.ninicoin.io/",
          },
        },
      },
      "custom-defiwallet": {
        display: {
          logo: defiwalletlogo,
          name: "Crypto.com DeFi Wallet",
          description: "Connect with DeFi Wallet browser extension or app",
        },
        options: {},
        package: WalletConnectProvider,
        connector: async () => {
          const connector = new DeFiWeb3Connector({
            supportedChainIds: [56],
            rpc: {
              56: "https://bsc-dataseed1.ninicoin.io/", // cronos mainet
            },
          });

          await connector.activate();
          setConnector(connector);
          let provider = await connector.getProvider();
          return provider;
        },
      },

      injected: {
        display: {
          logo: "https://github.com/MetaMask/brand-resources/raw/master/SVG/metamask-fox.svg",
          name: "MetaMask",
          description: "Connect with MetaMask in your browser",
        },
        package: null,
      },
    };

    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions, // required
      theme: {
        background: `#000`,
        main: "#fff",
        secondary: "#fff ",
        border: "#000",
        hover: "#000",
      },
    });

    if (provider) {
      web3Modal.clearCachedProvider();
      const acc = await provider.send("eth_requestAccounts", []);
      setaccount(acc[0]);
      console.log("account", account);
    } else {
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection, "any");
      const signer = provider.getSigner();
      const network = await provider.getNetwork();
      const chainId = network.chainId;
      console.log(chainId);
      setchainId(chainId);
      //  const acc = await provider.send('eth_requestAccounts', [0]);
      setaccount(signer.getAddress());

      provider.on("accountsChanged", (accounts) => {
        setaccount(accounts[0]);
      });

      provider.on("disconnect", (error) => {
        web3Modal.clearCachedProvider();
        window.location.reload();
      });

      setProvider(provider);
    }
  };
  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }
  const getRemaining = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed1.ninicoin.io/"
    );

    const contract = new ethers.Contract(
      "0x5099d14FBdc58039D68dB2eb4Fa3fa939da668B1",
      abi,
      provider
    );
    const total = await contract.totalSupply();
    const _Cost = await contract.cost();

    setCost(toEther(_Cost.toString()));

    setTotalSupply(total.toString());
    while (true) {
      await timeout(13000); //for 13 sec delay

      const total = await contract.totalSupply();
      const _Cost = await contract.cost();

      setCost(toEther(_Cost.toString()));

      setTotalSupply(total.toString());
    }
  };

  const getMintCost = async () => {
    try {
      const signer = provider.getSigner();
      const acc = await provider.send("eth_requestAccounts", []);
      setaccount(acc[0]);
      console.log("account", account);

      const contract = new ethers.Contract(
        "0x5099d14FBdc58039D68dB2eb4Fa3fa939da668B1",
        abi,
        signer
      );

      const price = await contract.cost();
      let bnprice = Web3.utils.fromWei(price.toString(), "ether");
      let weiprice = Web3.utils.toWei(price.toString(), "ether");
      setCost(bnprice);
      setMCost(weiprice);
      console.log("price", bnprice);
    } catch (error) {
      console.log("err", error);
    }
  };

  const mint = async () => {
    try {
      if (chainId === _chainId) {
        setFeedback(`Your  NFT is minting...`);
        setClaimingNft(true);

        const signer = provider.getSigner();

        const contract = new ethers.Contract(
          "0x5099d14FBdc58039D68dB2eb4Fa3fa939da668B1",
          abi,
          signer
        );
        let Tcost = Cost * mintAmount;
        let totalwei = Web3.utils.toWei(Tcost.toString(), "ether");
        let TotalCost = totalwei * mintAmount;
        let _gas = setgas(mintAmount);

        console.log("TotalCost", totalwei);
        console.log("mintAmount", mintAmount);
        const tx = await contract.mint(mintAmount, {
          from: account,
          value: totalwei,
        });

        setFeedback(
          `Congrats, you have minted your NFT! You can now view it under the “NFTs” section of your Metamask/Trust wallet.`
        );
        setClaimingNft(false);
      } else {
        alert("Wrong Network Swtich To " + " " + _chainname);
      }
    } catch (error) {
      console.log(error);

      setFeedback("Sorry, something went wrong please try again later.");
      setClaimingNft(false);
    }
  };

  useEffect(() => {
    getRemaining();
  }, []);

  const claimNFTs = () => {
    let cost = data.price;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! Check MetaMask to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 100) {
      newMintAmount = 100;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const setgas = (MintAmount) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    if (MintAmount == 1) {
      gasLimit = 111003;
    } else if (MintAmount == 2) {
      gasLimit = 118048;
    } else if (MintAmount == 3) {
      gasLimit = 125094;
    } else if (MintAmount == 4) {
      gasLimit = 132139;
    } else if (MintAmount == 5) {
      gasLimit = 139185;
    } else if (MintAmount == 6) {
      gasLimit = 146230;
    } else if (MintAmount == 7) {
      gasLimit = 153278;
    } else if (MintAmount == 8) {
      gasLimit = 160324;
    } else if (MintAmount == 9) {
      gasLimit = 167370;
    } else if (MintAmount == 10) {
      gasLimit = 174416;
    } else if (MintAmount == 11) {
      gasLimit = 181462;
    } else if (MintAmount == 12) {
      gasLimit = 188508;
    } else if (MintAmount == 13) {
      gasLimit = 195554;
    } else if (MintAmount == 14) {
      gasLimit = 202600;
    } else if (MintAmount == 15) {
      gasLimit = 209640;
    } else if (MintAmount == 16) {
      gasLimit = 216685;
    } else if (MintAmount == 17) {
      gasLimit = 223731;
    } else if (MintAmount == 18) {
      gasLimit = 230776;
    } else if (MintAmount == 19) {
      gasLimit = 237822;
    } else if (MintAmount == 20) {
      gasLimit = 244867;
    }

    return gasLimit;
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);
  return (
    <div id="mint" className="WalletSection">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="row heroImages">
              <div className="col-6">
                <Fade>
                  <img src="/images/wallet-img-1.webp" alt="click here" />
                </Fade>
              </div>
              <div className="col-6 mb-5">
                <Fade>
                  <img src="/images/wallet-img-2.webp" alt="click here" />
                </Fade>
              </div>
              <div className="col-6 mb-4 mb-md-0">
                <Fade>
                  <img src="/images/wallet-img-3.webp" alt="click here" />
                </Fade>
              </div>
              <div className="col-6">
                <Fade>
                  <img src="/images/wallet-img-4.webp" alt="click here" />
                </Fade>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="walletContainer">
              <div className="walletTextContainer">
                <div className="walletText">
                  <p>NFT Minted</p>
                </div>
                <div className="walletText">
                  <strong>{totalSupply}</strong> /{" "}
                  <strong>{CONFIG.MAX_SUPPLY}</strong>
                </div>
              </div>

              <div className="walletTextContainer">
                <div className="walletText">
                  <p>Price</p>
                </div>
                <div className="walletText">
                  <strong>{Cost * mintAmount} BNB</strong>Ξ
                </div>
              </div>

              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <>
                  {/* <p style={{ textAlign: "center", color: "var(--accent-text)" }}>The sale has ended.</p>
                  <p style={{ textAlign: "center", color: "var(--accent-text)" }}>
                    You can still find {CONFIG.NFT_NAME} on
                  </p>
                  <p target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                    {CONFIG.MARKETPLACE}
                  </p> */}
                </>
              ) : (
                <>
                  {/* <p style={{ textAlign: "center", color: "var(--accent-text)" }}>
                    1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST} {CONFIG.NETWORK.SYMBOL}.
                  </p>
                  <p style={{ textAlign: "center", color: "var(--accent-text)" }}>Excluding gas fees.</p> */}
                  {!provider ? (
                    <>
                      {/* <p
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        Connect to the {CONFIG.NETWORK.NAME} network
                      </p> */}
                      {/* <s.SpacerSmall /> */}
                      <a
                        className="walletBtn"
                      
                      >
                        SOLD OUT
                      </a>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <p
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </p>
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <p
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {feedback}
                      </p>
                      <div className="adding walletActionBtn mb-4">
                        <div
                          className="addingBtn"
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            decrementMintAmount();
                          }}
                        >
                          <AiOutlineMinus />
                        </div>
                        {/* <p className="quantity mb-0">{mintAmount}</p> */}
                        <input
                          className="quantity mb-0"
                          value={mintAmount}
                          type="text"
                          onChange={(e) =>
                            setMintAmount(Number(e.target.value))
                          }
                        />

                        <div
                          className="addingBtn"
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            incrementMintAmount();
                          }}
                        >
                          <AiOutlinePlus />
                        </div>
                      </div>
                      <a
                        className="walletBtn"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          mint();
                        }}
                      >
                        BUY
                      </a>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletSection2;

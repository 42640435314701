import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Nav, Navbar } from "react-bootstrap";
import logo from "../assets/img/WEBSITE FACE.webp";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

const PageHeader = () => {
  return (
    <>
      <Navbar className="pageHeader" expand="lg">
        <Navbar.Brand href="#home" className="d-flex justify-content-center align-items-center">
          <img src={logo} alt="" style={{width: "30%"}}/><p className="logo-font">NOMAIMAI</p>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#introduction">Introduction</Nav.Link>
            <Nav.Link href="#team">Team</Nav.Link>
            <Nav.Link href="#Faq">FAQ</Nav.Link>
            <Nav.Link href="#mint">Mint</Nav.Link>
          </Nav>
          <Nav className="ms-auto socialIcons">
            <Nav.Link href="https://twitter.com/nomaimainft">
              <AiOutlineTwitter />
            </Nav.Link>
            <Nav.Link href="#https://discord.gg/kdNdb43Hkw">
              <FaDiscord />
            </Nav.Link>
            <Nav.Link href="https://instagram.com/nomaimai.in">
              <AiFillInstagram />
            </Nav.Link>
          
            <Nav.Link className="headerBtn" href="#mint">
              Connect Wallet
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default PageHeader;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import bannerVdo from "../assets/vdo/2.webm";
import Fade from "./Fade";

import heroImg from "../assets/img/hero-img.webp";

function HeroSection() {
  return (
    <section className="HeroSection">
      <Container>
        <Row>
          <Col lg={10} className="mx-auto">
           
            <img src={heroImg} className="heroImg" alt="" />
            <Fade>
              <video
                className="bannerVdo"
                loop="true"
                autoplay="autoplay"
                controls={false}
                muted
              >
                <source type="video/mp4" src={bannerVdo}></source>
              </video>
            </Fade>
            <Fade>
              <p className="heroText">
              NoMaiMai is a set of 4,000 Non-Fungible Tokens (NFTs) comprising of hundreds of unique visual features, artistic characters and animations. NoMaiMai provides its holders with a bunch of real life utilities and perks and gives them the opportunity to become a part of the biggest growing investor community.
              </p>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HeroSection;

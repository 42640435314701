import React from "react";
import carouselImg1 from "../assets/img/carousel-img-1.webp";
import carouselImg2 from "../assets/img/carousel-img-2.webp";
import carouselImg3 from "../assets/img/carousel-img-3.webp";
import carouselImg4 from "../assets/img/carousel-img-4.webp";
import carouselImg5 from "../assets/img/carousel-img-5.webp";
import carouselImg6 from "../assets/img/carousel-img-6.webp";
import carouselImg7 from "../assets/img/carousel-img-7.webp";
import carouselImg8 from "../assets/img/carousel-img-8.webp";
import carouselImg9 from "../assets/img/carousel-img-9.webp";
import carouselImg10 from "../assets/img/carousel-img-10.webp";
import carouselImg11 from "../assets/img/carousel-img-11.webp";
import carouselImg12 from "../assets/img/carousel-img-12.webp";
import Fade from "./Fade";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselSection = () => {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 5.2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Fade>
      <section className="CarouselSection">
        <Slider {...settings}>
          <div>
            <img src={carouselImg1} alt="" />
          </div>
          <div>
            <img src={carouselImg2} alt="" />
          </div>
          <div>
            <img src={carouselImg3} alt="" />
          </div>
          <div>
            <img src={carouselImg4} alt="" />
          </div>
          <div>
            <img src={carouselImg5} alt="" />
          </div>
          <div>
            <img src={carouselImg6} alt="" />
          </div>
        </Slider>
        <Slider {...settings}>
          <div>
            <img src={carouselImg7} alt="" />
          </div>
          <div>
            <img src={carouselImg8} alt="" />
          </div>
          <div>
            <img src={carouselImg9} alt="" />
          </div>
          <div>
            <img src={carouselImg10} alt="" />
          </div>
          <div>
            <img src={carouselImg11} alt="" />
          </div>
          <div>
            <img src={carouselImg12} alt="" />
          </div>
        </Slider>
      </section>
    </Fade>
  );
};

export default CarouselSection;

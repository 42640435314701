import React from "react";
import { Container, Accordion } from "react-bootstrap";
import Fade from "./Fade";

const FaqSection = () => {
  return (
    <section id="Faq" className="FaqSection">
      <Container>
        <Fade>
          <h2 className="mainHeading">Frequently Asked Questions</h2>
        </Fade>
        <Accordion>
          <Fade>
            <Accordion.Item eventKey="0">
              <Accordion.Header>How many NoMaiMai will be available?</Accordion.Header>
              <Accordion.Body>
              NoMaiMai is a collection of 4000 unique NFTs stored as ERC-721 on the Binance blockchain and hosted on IPFS.
              </Accordion.Body>
            </Accordion.Item>
          </Fade>
          <Fade>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
              Where can I view my NFTs after minting?
             </Accordion.Header>
              <Accordion.Body>
              Once you have minted a NoMaiMai, you will be able to see it in your Trust/Metamask wallet.
              </Accordion.Body>
            </Accordion.Item>
          </Fade>
        
          <Fade>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
              How to connect my wallet?
             </Accordion.Header>
              <Accordion.Body>
              After you have created your wallet you will just have to click on the button on the header of this page 'Connect Wallet' and make sure you are on the Binance Network
              </Accordion.Body>
            </Accordion.Item>
          </Fade>
          
       
        </Accordion>
      </Container>
    </section>
  );
};

export default FaqSection;

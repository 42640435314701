import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import teamImg1 from "../assets/img/team-img-1.webp";
import teamImg2 from "../assets/img/team-img-2.webp";
import teamImg3 from "../assets/img/team-img-3.webp";
import Fade from "./Fade";

const TeamSection = () => {
  return (
    <section id="team" className="TeamSection">
      <h2 className="mainHeading">Team</h2>
      <Container>
        <Row>
          <Col lg={4}>
            <Fade>
              <div className="teamCard">
                <img src={teamImg1} alt="" />
                <h3 className="teamName">Tahair</h3>
                <div className="teamDec">Visual Design & Communication</div>
              </div>
            </Fade>
          </Col>
          <Col className="my-5 my-lg-0" lg={4}>
            <Fade>
              <div className="teamCard">
                <img src={teamImg2} alt="" />
                <h3 className="teamName">Shagun</h3>
                <div className="teamDec">Founder & CEO</div>
              </div>
            </Fade>
          </Col>
          <Col lg={4}>
            <Fade>
              <div className="teamCard">
                <img src={teamImg3} alt="" />
                <h3 className="teamName">Ali</h3>
                <div className="teamDec">Operations & Community</div>
              </div>
            </Fade>
          </Col>
        </Row>
        <Fade>
        
        </Fade>
      </Container>
    </section>
  );
};

export default TeamSection;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";
import Fade from "./Fade";

const Footer = () => {
  return (
    <Fade>
      <footer className="Footer">
        <Container>
          <Row>
            <Col lg={4}>
              <a href="#" style={{color:"white"}}>
                <p className="logo-font">NOMAIMAI</p>
              </a>
              <div className="contactLinks">
               </div>
            </Col>
            <Col className="my-5 my-lg-0" lg={4}>
              <a className="footerlink odd" href="#introduction">
                INTRODUCTION
              </a>
              <a className="footerlink odd" href="#mint">
                MINT
              </a>
              <a className="footerlink odd" href="#Faq">
                FAQ
              </a>
              <a className="footerlink odd" href="#team">
                Team
              </a>
            </Col>
            <Col lg={4}>
              <h4 className="footerHeading">FOLLOW US</h4>
              <a className="socialIcon" href="https://twitter.com/nomaimainft">
                <AiOutlineTwitter />
              </a>
              <a className="socialIcon" href="https://discord.gg/kdNdb43Hkw">
                <FaDiscord />
              </a>
              <a className="socialIcon" href="https://instagram.com/nomaimai.in">
                <AiFillInstagram />
              </a>
            
              <p className="copyRight">
                Copyright ©️<span>Nomaimai</span> All rights reserved.
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fade>
  );
};

export default Footer;
